require('./logo.png');
require('./logo-black.png');
require('./logo-icon.png');
require('./logo-black-2023.png');
require('./logo-white-2023.png');
require('./logo-icon-white.png');
require('./footer-background.png');
require('./photo-comp-icon.png');
require('./placeholder-icon.png');
require('./video-icon.png');
require('./review-widget.png');

require('../svgs/logo-black.svg');
require('../svgs/logo-white.svg');
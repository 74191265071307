require('jquery-isonscreen');
require('lodash');

$(window).on('load resize scroll checkForAnimations', _.throttle(function () {
    let onScreenOffsetX = .2,
        onScreenOffsetY = .2;

    $('[data-modal-target="video-popup"]').each(function () {
        if ($(this).isOnScreen(onScreenOffsetX, onScreenOffsetY) && !$(this).hasClass('on-screen')) {
            $(this).addClass('on-screen');

            let player = $(this).find('.youtube-player');
            let iFrame = document.createElement('iframe');
            iFrame.setAttribute('src', player.data('id'));
            iFrame.setAttribute('frameborder', player.data('frameborder'));
            iFrame.setAttribute('allow', player.data('allowed'));
            iFrame.setAttribute('allowfullscreen', player.data('allowfullscreen'));
            player.append(iFrame);
        }
    });
}, 400));
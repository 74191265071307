/**
 * Scrolls to the element on screen
 */
let scrollLinks = document.querySelectorAll('a[href*="#"]');
if (scrollLinks.length > 0) {
    scrollLinks.forEach(scrollLink => {
        scrollLink.addEventListener('click', function (event) {
            let link = scrollLink.getAttribute('href');

            if (link.startsWith('#') && link.length > 1) {
                event.preventDefault();
                let element = document.querySelector(link);

                window.scrollTo({
                    'behavior': 'smooth',
                    'top': element.offsetTop,
                });
            } else if (scrollLink.classList.contains('back-to-top') || scrollLink.classList.contains('jump-to-top')) {
                event.preventDefault();

                window.scrollTo({
                    'behavior': 'smooth',
                    'top': 0,
                });
            } else {
                if (scrollLink.hasAttribute('data-behaviour')) {
                    event.preventDefault();

                    if (scrollLink.getAttribute('data-behaviour') === 'scroll-to') {
                        window.scrollTo({
                            'behavior': 'smooth',
                            'top': event.currentTarget.closest('[class^="c-"]').nextElementSibling.offsetTop,
                        });
                    }
                }
            }
        });
    });
}
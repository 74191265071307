require('lodash');

const backToTopElement = document.querySelector('[data-behaviour="back-to-top"]');

window.addEventListener('scroll', _.throttle(() => {
    let windowPosition = window.pageYOffset;
    let windowHeight = window.innerHeight;

    if (windowPosition >= windowHeight) {
        backToTopElement.classList.add('--active')
    } else {
        backToTopElement.classList.remove('--active')
    }
}, 400));
require('jquery-isonscreen');
require('lodash');

function checkForElementsToAnimate() {
    let jsAnimateSelector = $('[data-behaviour="animate-when-visible"]'),
        onScreenOffsetX = .2,
        onScreenOffsetY = .2,
        mobOnScreenOffsetX = .05,
        mobOnScreenOffsetY = .05,
        wysiwygOnScreenOffsetX = .25,
        wysiwygOnScreenOffsetY = .25,
        onScreenBreakPoint = 679,
        onScreen;

    if (jsAnimateSelector.length < 1) return;

    jsAnimateSelector.each(function () {
        let delayAnimation = $(this).data('delay-animation'),
            alreadyOnScreen = $(this).offset().top < $(window).scrollTop(),
            wysiwygChildOnScreen = $(this).hasClass('user-content') && $(this).children().isOnScreen(wysiwygOnScreenOffsetX, wysiwygOnScreenOffsetY);

        if ($(window).width() < onScreenBreakPoint) {
            onScreen = $(this).isOnScreen(mobOnScreenOffsetX, mobOnScreenOffsetY);
            delayAnimation = false;
        } else {
            onScreen = $(this).isOnScreen(onScreenOffsetX, onScreenOffsetY);
        }

        if (onScreen || alreadyOnScreen || wysiwygChildOnScreen) {
            if (delayAnimation) {
                $(this).css('animation-delay', delayAnimation);
            }

            $(this).addClass('animate');
        }
    });
}

$(window).on('load resize scroll checkForAnimations', _.throttle(function () {
    checkForElementsToAnimate();
}, 400));

// polyfill for forEach
(function () {
    if (typeof NodeList.prototype.forEach === "function") return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
})();

// Polyfill for scrollTo
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

import '../images';

import './modules';
import './components';

import RemoveFocusState from 'remove-focus-state'

new RemoveFocusState();

import {FontLoader} from './tools';

const fonts = new FontLoader([
    {name: 'Helvetica Neue LT W05 55 Roman'},
    {name: 'Helvetica Neue LT W05 77 Bd Cn'},
    {name: 'Helvetica LT W05 Bold Condense'},
]);

fonts.load();

const htmlElement = document.getElementsByTagName('html')[0];

htmlElement.classList.remove('no-js');

setTimeout(function () {
    if (!htmlElement.classList.contains('loaded')) {
        htmlElement.classList.add('sites-slow');
    }
}, 3000);

window.addEventListener('load', () => {
    htmlElement.classList.add('loaded');
    htmlElement.classList.remove('sites-slow');
});

const showTableRows = document.querySelectorAll('[data-behaviour="show-table-rows"]');

showTableRows.forEach((element) => {
    const parent = element.closest('.itinerary-block');

    element.addEventListener('click', (e) => {
        e.preventDefault();

        const items = parent.querySelectorAll('.--hidden');

        items.forEach((item) => {
            item.classList.remove('--hidden');
        });

        parent.classList.add('--active');
    });
});
require('jquery-isonscreen');
require('lodash');

const navigationElements = document.querySelectorAll('[data-behaviour="toggle-sticky-nav"]');
const stickyElements = document.querySelectorAll('.sticky-nav-bar');

navigationElements.forEach(stickyNavigation => {
    stickyNavigation.addEventListener('click', (e) => {
        e.preventDefault();

        const parent = e.currentTarget.closest('.sticky-nav-bar');
        parent.classList.toggle('sticky-nav-bar--open');
    });
});

stickyElements.forEach((stickyElement) => {
    const items = stickyElement.querySelectorAll('[data-behaviour="scroll-to"]');

    items.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            stickyElement.classList.remove('sticky-nav-bar--open');
        });
    });
});

let onScreenOffsetX = .2,
    onScreenOffsetY = .2;

let lastID;

$(window).on('scroll', _.throttle(function () {
    $('.content-section[id]').each(function () {
        const section = $(this);

        if (section.isOnScreen(onScreenOffsetX, onScreenOffsetY)) {
            section.addClass('waypoint-trigger');
        } else {
            section.removeClass('waypoint-trigger');
        }
    });

    if ($('.waypoint-trigger').length >= 1) {
        let current = $('.waypoint-trigger:last').attr('id');

        if (lastID !== current) {
            lastID = current;
            console.log(lastID);

            $('.sticky-nav-bar a').removeClass('--active');
            $('.sticky-nav-bar a[href="#' + lastID + '"]').addClass('--active');
        }
    }
}, 500));
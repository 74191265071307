import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

function getGalleryItems(element) {
    const $slides = element.find('.lightbox-items .item'),
        items = [],
        windowWidth = $(window).width(),
        windowHeight = $(window).height();

    if ($slides.length > 0) {
        $slides.each(function (i, el) {
            let img = $(el).find('img');
            let title = '<span>' + $(el).data('title') + '</span>';

            if (img.length) {
                let img_src = img.attr('src'),
                    item = {
                        src: img_src,
                        w: windowWidth,
                        h: windowHeight,
                        title: title
                    };
                items.push(item);
            }
        });
    }

    return items;
}

const openPhotoSwipe = function (index, element) {
    let pswpElement = document.querySelector('.pswp');
    let items = getGalleryItems(element);
    let options = {
        index: index ? index : 0,
        history: false,
        focus: false
    };

    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
};

$('.photo-competition__grid').on('click', '.photo-entry', function (e) {
    e.preventDefault();

    let index = $(this).data('index');
    openPhotoSwipe(index, $(this).parents('.photo-competition__grid'));
});

$('.gallery__container').on('click', '.gallery-item', function (e) {
    e.preventDefault();

    let index = $(this).data('index');
    openPhotoSwipe(index, $(this).parents('.gallery__container'));
});

$('body').on('click', '.js-fire-modal', function (e) {
    e.preventDefault();
    window.dispatchEvent(new Event('closeModals'));

    const modalTarget = $(this).attr('data-modal-target');

    $('.js-modal[data-modal-id="' + modalTarget + '"]').addClass('modal--active');
    $('body').addClass('lock-scrolling');
});

document.querySelectorAll('[data-modal-target="video-popup"]').forEach((element) => {
    element.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector('[data-modal-id="video-popup"] .modal__content').innerHTML = e.currentTarget.getElementsByClassName('js-video')[0].outerHTML;
        document.querySelector('[data-modal-id="video-popup"] .modal__content iframe').src += '&autoplay=1'
    });
});

$(document).on('click', '.modal--active *', function (e) {
    e.stopPropagation();
});

$(document).on('click', '.js-modal, .js-close-modal', function (e) {
    e.preventDefault();

    window.dispatchEvent(new Event('closeModals'));
});

$(window).on('closeModals', function () {
    const modalTarget = $('.js-modal.modal--active');

    // Remove videos on close
    modalTarget.find('iframe').remove();

    modalTarget.removeClass('modal--active');
    $('body').removeClass('lock-scrolling');
});

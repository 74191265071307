import Utilities from "./utilities";

const utilities = new Utilities();
const queryString = utilities.getUrlParameter('filter-region');
const toggleLocations = document.querySelectorAll('[data-behaviour="toggle-locations"]');
const expeditions = document.querySelectorAll('.horizontal-card[data-location]');

toggleLocations.forEach((element) => {
    element.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.currentTarget.dataset.target;

        toggleLocations.forEach((siblings) => {
            siblings.classList.remove('--active');
        });

        e.currentTarget.classList.add('--active');

        expeditions.forEach((expedition) => {
            if (target === 'all') {
                expedition.classList.remove('--hidden');
                return;
            }

            if (expedition.dataset.location !== target) {
                expedition.classList.add('--hidden');
            } else {
                expedition.classList.remove('--hidden');
            }
        });

        window.dispatchEvent(new Event('checkForAnimations'));
    });
});

if (queryString) {
    toggleLocations.forEach((element) => {
        const target = element.dataset.target;
        if (queryString === target) {
            element.click();
        }
    });
}
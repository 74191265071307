import React from 'react';

export default class PeopleGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: true,
            results: null,
            filteredResults: [],
            jobLevel: '',
            location: '',
            taxonomyLevel: '',
            taxonomyLocation: '',
        };
    }
    componentDidMount() {
        fetch(`${rest_object.api_url}camps/get-people/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        results: result,
                        isLoading: false,
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        error: error,
                    });
                }
            );

        fetch(`${rest_object.api_url}camps/get-term/?term=job_location`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        taxonomyLocation: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        error: error,
                    });
                }
            );

        fetch(`${rest_object.api_url}camps/get-term/?term=job_level`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        taxonomyLevel: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        error: error,
                    });
                }
            );
    }

    handleLocationChange = (e) => {
        const location = e.target.value;
        const jobLevel = this.state.jobLevel;
        const filteredResults = this.state.results.filter((item) => {
            if (!item.location) return console.log(item.title + ' is missing a location');
            if (jobLevel) {
                if (
                    location === '' &&
                    item.job_level.indexOf(jobLevel) !== -1
                ) {
                    return item;
                } else if (
                    item.location.indexOf(location) !== -1 &&
                    item.job_level.indexOf(jobLevel) !== -1
                ) {
                    return item;
                } else {
                    return false;
                }
            }

            return item.location.indexOf(location) !== -1;
        });

        this.setState({ location, jobLevel, filteredResults });
    };

    handleJobLevelChange = (e) => {
        const jobLevel = e.target.value;
        const location = this.state.location;

        const filteredResults = this.state.results.filter((item) => {
            if (location) {
                if (jobLevel === '' && item.location.indexOf(location) !== -1) {
                    return item;
                } else if (
                    item.job_level.indexOf(jobLevel) !== -1 &&
                    item.location.indexOf(location) !== -1
                ) {
                    return item;
                } else {
                    return false;
                }
            }

            return item.job_level.indexOf(jobLevel) !== -1;
        });

        this.setState({ location, jobLevel, filteredResults });
    };

    renderInputItem(item, index) {
        return (
            <option value={item.slug} key={index}>
                {item.name}
            </option>
        );
    }

    renderForm() {
        const {
            location,
            jobLevel,
            taxonomyLevel,
            taxonomyLocation,
        } = this.state;
        const locationItems = Object.values(taxonomyLocation).map(
            (item, index) => {
                return this.renderInputItem(item, index);
            }
        );
        const levelItems = Object.values(taxonomyLevel).map((item, index) => {
            return this.renderInputItem(item, index);
        });

        return (
            <form className="standard-form">
                <div className="form-fields">
                    <div className="form-row third">
                        <div className="dropdown">
                            <label className="hide-label" htmlFor="location">
                                Location
                            </label>
                            <select
                                name="location"
                                onChange={this.handleLocationChange}
                                value={location}
                            >
                                <option value="">Location</option>
                                {locationItems}
                            </select>
                        </div>
                    </div>
                    {/* <div className="form-row third">
                        <div className="dropdown">
                            <label className="hide-label" htmlFor="job-level">
                                Job level
                            </label>
                            <select
                                name="job-level"
                                onChange={this.handleJobLevelChange}
                                value={jobLevel}
                            >
                                <option value="">Job level</option>
                                {levelItems}
                            </select>
                        </div>
                    </div> */}
                </div>
            </form>
        );
    }

    renderItem(item, index) {
        return (
            <a className="person " href={item.permalink} key={index}>
                <div
                    className="person__image"
                    dangerouslySetInnerHTML={{ __html: item.image_markup }}
                ></div>
                <div className="person__content">
                    <div
                        className="modal-image"
                        dangerouslySetInnerHTML={{ __html: item.image_markup }}
                    ></div>
                    <div className="modal-details">
                        <h2 className="title">{item.title}</h2>
                        <div className="modal-details__row">
                            {item.job_title !== '' && (
                                <span>{item.job_title}</span>
                            )}
                            {item.job_location !== '' && (
                                <span>{item.job_location}</span>
                            )}
                        </div>
                        {item.content !== '' && (
                            <div>
                                <hr />
                                <div
                                    className="user-content"
                                    dangerouslySetInnerHTML={{
                                        __html: item.content,
                                    }}
                                ></div>
                            </div>
                        )}
                    </div>
                </div>
            </a>
        );
    }

    loadRender() {
        return <div className="loader"></div>;
    }

    render = () => {
        const { error, isLoading, results, filteredResults } = this.state;
        if (isLoading) return this.loadRender();

        if (error) {
            console.log(error);
            return this.loadRender();
        }

        const renderItems =
            filteredResults.length > 0 ? filteredResults : results;

        renderItems.sort(function(a, b) {
            if (!Array.isArray(a.job_level)) return -1;
            if (!Array.isArray(b.job_level)) return 1;

            if (a.job_level[0] == b.job_level[0]) {
                return 0;
            } else if (a.job_level[0] == 'directors') {
                return -1;
            } else if (b.job_level[0] == 'directors') {
                return 1;
            } else {
                return 0;
            }
        });

        const items = Object.values(renderItems).map((item, index) => {
            return this.renderItem(item, index);
        });

        return (
            <div className="people-grid__wrapper">
                <div className="container">
                    <header className="filters">
                        <div className="title title--with-line">
                            <span>Filter by</span>
                        </div>
                        {this.renderForm()}
                    </header>
                </div>
                <div className="inner-content">
                    <div className="container container--max-width container--no-padding">
                        <div className="people-grid__grid">{items}</div>
                    </div>
                </div>
            </div>
        );
    };
}

const showMoreInformation = document.querySelectorAll('[data-behaviour="camp-show-more"]');

showMoreInformation.forEach((element) => {
    const parent = element.closest('.horizontal-card');

    element.addEventListener('click', (e) => {
        e.preventDefault();

        const items = parent.querySelectorAll('.--hidden');

        items.forEach((item) => {
            item.classList.remove('--hidden');
        });

        parent.classList.add('--active');
    });
});
import React from 'react';
import ReactDOM from 'react-dom';

import CareersGrid from './CareersGrid/CareersGrid';
import PeopleGrid from './PeopleGrid/PeopleGrid';

const CareersGridElement = document.querySelector('[data-react="render-careers-grid"]');
if (CareersGridElement) {
    ReactDOM.render(<CareersGrid/>, CareersGridElement);
}

const PeopleGridElement = document.querySelector('[data-react="render-people-grid"]');
if (PeopleGridElement) {
    ReactDOM.render(<PeopleGrid/>, PeopleGridElement);
}

import React from 'react';

export default class CareersGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: true,
            results: null,
            filteredResults: [],
            hours: '',
            location: '',
            taxonomyHours: '',
            taxonomyLocation: '',
        };
    }

    componentDidMount() {
        fetch(`${rest_object.api_url}camps/get-careers/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        })
            .then(res => res.json())
            .then((result) => {
                    this.setState({
                        results: result,
                        isLoading: false
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        error: error
                    });
                });

        fetch(`${rest_object.api_url}camps/get-term/?term=career_location`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(res => res.json())
            .then((result) => {
                    this.setState({
                        taxonomyLocation: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        error: error
                    });
                });

        fetch(`${rest_object.api_url}camps/get-term/?term=career_hours`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(res => res.json())
            .then((result) => {
                    this.setState({
                        taxonomyHours: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        error: error
                    });
                });
    }

    handleLocationChange = (e) => {
        const location = e.target.value;
        const hours = this.state.hours;

        const filteredResults = this.state.results.filter((item) => {
            if (hours) {
                if (item.location === location && item.hours === hours) {
                    return item;
                } else {
                    return false;
                }
            }

            return item.location === location;
        });

        console.log(filteredResults);
        this.setState({location, hours, filteredResults});
    };

    handleHoursChange = (e) => {
        const hours = e.target.value;
        const location = this.state.location;

        const filteredResults = this.state.results.filter((item) => {
            if (location) {
                if (item.hours === hours && item.location === location) {
                    return item;
                } else {
                    return false;
                }
            }

            return item.hours === hours;
        });

        this.setState({location, hours, filteredResults});
    };

    renderInputItem(item, index) {
        return (
            <option value={item.slug} key={index}>{item.name}</option>
        )
    }

    renderForm() {
        const {location, hours, taxonomyHours, taxonomyLocation} = this.state;
        const locationItems = Object.values(taxonomyLocation).map((item, index) => {
            return this.renderInputItem(item, index);
        });
        const hoursItems = Object.values(taxonomyHours).map((item, index) => {
            return this.renderInputItem(item, index);
        });

        return (
            <form className="standard-form">
                <div className="form-fields">
                    <div className="form-row third">
                        <div className="dropdown">
                            <label className="hide-label" htmlFor="location">Location</label>
                            <select name="location" onChange={this.handleLocationChange} value={location}>
                                <option value="">Location</option>
                                {locationItems}
                            </select>
                        </div>
                    </div>
                    <div className="form-row third">
                        <div className="dropdown">
                            <label className='hide-label' htmlFor="hours">Hours</label>
                            <select name="hours" onChange={this.handleHoursChange} value={hours}>
                                <option value="">Hours</option>
                                {hoursItems}
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    renderItem(item, index) {
        return (
            <div className="horizontal-card u-box-shadow"
                 key={index}
                 data-location={item.location}
                 data-hours={item.hours}>
                <div className={`horizontal-card__background-image background-image--${item.background_image_ID}`}>
                    <a className="horizontal-card__link"
                       href={item.url}
                       dangerouslySetInnerHTML={{__html: item.background_image_markup}}></a>
                </div>
                <div className="horizontal-card__image">
                    <a className="horizontal-card__link"
                       href={item.url} dangerouslySetInnerHTML={{__html: item.image_markup}}></a>
                </div>
                <div className="horizontal-card__content">
                    <h3 className="title">
                        <a className="horizontal-card__link"
                           href={item.url}>
                            {item.title}
                        </a>
                    </h3>
                    <div className="horizontal-card__details" dangerouslySetInnerHTML={{__html: item.content}}></div>
                    <div className="horizontal-card__usps">
                        <div className="usp">
                            <div className="usp__icon"
                                 dangerouslySetInnerHTML={{__html: item.location_icon_markup}}></div>
                            <div className="usp__content">
                                <div className="header-6 u-small-text">Location:</div>
                                <div className="u-small-text">{item.location_name}</div>
                            </div>
                        </div>
                        <div className="usp">
                            <div className="usp__icon" dangerouslySetInnerHTML={{__html: item.hours_icon_markup}}></div>
                            <div className="usp__content">
                                <div className="header-6 u-small-text">Hours:</div>
                                <div className="u-small-text">{item.hours_name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <a className="button button--outline"
                           href={item.url}>
                            Find out more
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    loadRender() {
        return (<div className="loader"></div>);
    }

    render = () => {
        const {error, isLoading, results, filteredResults} = this.state;
        if (isLoading) return this.loadRender();

        if (error) {
            console.log(error);
            return this.loadRender()
        }

        const renderItems = filteredResults.length > 0 ? filteredResults : results;
        const items = Object.values(renderItems).map((item, index) => {
            return this.renderItem(item, index);
        });

        return (
            <div className="careers-listing">
                <section className="c-post-filters post-filters content-section">
                    <div className="container">
                        <header className="filters">
                            <div className="title title--with-line">
                                <span>Filter by</span>
                            </div>
                            {this.renderForm()}
                        </header>
                    </div>
                </section>
                <section className="c-listing-cards listing-cards content-section content-section--white-smoke">
                    <div className="careers-grid__wrapper">
                        <div className="container">
                            <div className="listing-cards__grid">
                                {items}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

import Utilities from "./utilities";

const utilities = new Utilities();

const navigationElement = document.querySelectorAll('[data-behaviour="navigation-toggle"]');
const headerElement = document.getElementsByClassName('site-header')[0];
const popoutMenu = document.getElementsByClassName('popout-menu__inner')[0];
const bodyElement = document.querySelector('body');
const menuItemHasChildren = document.querySelectorAll('.main-navigation .menu-item-has-children');
const subMenuItems = document.querySelectorAll('.main-navigation .sub-menu a');


navigationElement.forEach(navElement => {
    navElement.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.currentTarget.getAttribute('data-toggle');
        const activeClass = target + '-open';

        headerElement.classList.toggle('site-header--' + activeClass);
        bodyElement.classList.toggle('--' + activeClass);

        menuItemHasChildren.forEach((item) => {
            if (utilities.hasClass(item, '--open')) {
                item.classList.remove('--open');
            }

            // Reset inner scroll
            item.querySelector('.sub-menu').scrollTop = 0;
        });

        // Reset inner scroll
        popoutMenu.scrollTop = 0;
    });
});

menuItemHasChildren.forEach((el) => {
    el.addEventListener('click', (e) => {
        e.preventDefault();

        menuItemHasChildren.forEach((item) => {
            if (utilities.hasClass(item, '--open') && e.currentTarget !== item) {
                item.classList.remove('--open');
            }
        });

        e.currentTarget.classList.toggle('--open');
    });
});

subMenuItems.forEach((item) => {
    item.addEventListener('click', (e) => {
        e.stopPropagation();

        if (e.target.href.indexOf("#") !== false) {
            headerElement.classList.remove('site-header--menu-open');
            bodyElement.classList.remove('--menu-open');

            menuItemHasChildren.forEach((item) => {
                if (utilities.hasClass(item, '--open')) {
                    item.classList.remove('--open');
                }
            });
        }
    });
});

const animateDuration = 500;
const scrollElement = document.querySelector('.tax-comp_year');
const photoCompElement = document.querySelector('.c-photo-competition');

$(window).on('load', function () {
    if (scrollElement && photoCompElement) {
        scrollPosition = photoCompElement.offsetTop;

        $('html, body').animate({
            scrollTop: scrollPosition
        }, animateDuration);
    }
});


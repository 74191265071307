const animationDuration = 300;
const tabs = document.querySelector('.tabs');

$(document).on('click', '[data-behaviour="toggle-tab"]', function (e) {
    e.preventDefault();
    const tabSelector = $('[data-behaviour="toggle-tab"]');
    let openTab = $(this).data('tab');

    window.location.hash = '#' + openTab;
    tabSelector.removeClass('tabs__selector--active');
    $(this).addClass('tabs__selector--active');
    $('.tabs__content').removeClass('tabs__content--active');
    $('#' + openTab).addClass('tabs__content--active');

    window.dispatchEvent(new Event('triggerTabChange'));
});

function fakeClickEvent() {
    const windowHash = window.location.hash;

    if (windowHash && tabs !== false) {
        const tabButton = document.querySelector('a[href="' + windowHash + '"]');

        if (tabButton) {
            tabButton.click();
        }
    }
}

window.addEventListener('hashchange', fakeClickEvent, false);
window.addEventListener('load', fakeClickEvent, false);


const scrollOffset = 140;
const animateDuration = 500;
let scrollPosition;

$(window).on('load', function () {
    if ($('.validation_message').length > 0 || $('.gform_confirmation_message').length > 0) {
        if ($('.validation_message').length > 0 && $('.gform_wrapper .validation_error').length > 0) {
            scrollPosition = $('.gform_wrapper .validation_error').parents('.gform_wrapper').offset().top - scrollOffset;
        }

        if ($('.gform_confirmation_message').length > 0) {
            scrollPosition = $('.gform_confirmation_message').offset().top - scrollOffset;
        }

        $('html, body').animate({
            scrollTop: scrollPosition
        }, animateDuration);
    }

    $('.gfield').on('change', 'input, select, textarea', function () {
        $(this).parents('.gfield_error').removeClass('gfield_error');
    });
});

import {tns} from 'tiny-slider/src/tiny-slider';

const post_card_slider = document.querySelectorAll('[data-behaviour="post-scroller"]');

if (post_card_slider.length > 0) {
    post_card_slider.forEach(slider => {
        const post_slider = tns({
            container: slider,
            loop: false,
            nav: false,
            mouseDrag: true,
            gutter: 4,
            items: 1
        });

        post_slider.events.on('transitionEnd', function () {
            window.dispatchEvent(new Event('checkForAnimations'));
        });
    });
}

const goals_slider = document.querySelectorAll('[data-behaviour="goal-scroller"]');

if (goals_slider.length > 0) {
    goals_slider.forEach(slider => {
        tns({
            container: slider,
            loop: false,
            nav: false,
            mouseDrag: false,
            items: 3
        });
    });
}

const card_goals_slider = document.querySelectorAll('[data-behaviour="card-goal-scroller"]');

if (card_goals_slider.length > 0) {
    card_goals_slider.forEach(slider => {
        tns({
            container: slider,
            loop: false,
            nav: false,
            mouseDrag: false,
            items: 5,
            responsive: {
                0: {
                    items: 2
                },
                425: {
                    items: 3
                },
                679: {
                    items: 4
                },
                769: {
                    items: 5
                },
            }
        });
    });
}

const impact_slider = document.querySelectorAll('[data-behaviour="impact-scroller"]');

if (impact_slider.length > 0) {
    impact_slider.forEach(slider => {
        tns({
            container: slider,
            loop: false,
            nav: false,
            mouseDrag: true,
            items: 1
        });
    });
}

const filter_slider = document.querySelectorAll('[data-behaviour="filter-slider"]');

if (filter_slider.length > 0) {
    filter_slider.forEach(slider => {
        const activeItem = slider.querySelector('.--active');
        const index = [...slider.children].indexOf(activeItem);

        tns({
            container: slider,
            loop: false,
            nav: false,
            mouseDrag: true,
            gutter: 30,
            items: 4,
            startIndex: index,
            responsive: {
                0: {
                    items: 1
                },
                679: {
                    items: 2
                },
                769: {
                    items: 3
                },
                1025: {
                    items: 4
                }
            }
        });
    });
}

const testimonials = document.querySelectorAll('[data-behaviour="testimonial-slider"]');

if (testimonials.length > 0) {
    testimonials.forEach(slider => {
        tns({
            container: slider,
            loop: true,
            nav: false,
            mouseDrag: true,
            items: 1
        });
    });
}

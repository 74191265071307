require('lodash');

function animateNumbers(element) {
    const start = parseInt(element.html());
    const end = parseInt(element.data('end-number'));
    const target = element;
    const duration = 4000;

    element.prop('Counter', start).animate(
        {
            Counter: end
        },
        {
            duration: duration,
            easing: 'linear',
            step: function (now) {
                $(this).addClass('running');
                target.text(Number(Math.ceil(now)).toLocaleString('en'));
            }
        }
    );
}

$(window).on('load resize scroll', _.throttle(function () {
    $('.stat__number').each(function () {
        if ($(this).hasClass('animate') && !$(this).hasClass('running')) {
            $(this).addClass('running');

            animateNumbers($(this).find('.digit'));
        }
    });
}, 400));

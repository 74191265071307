import Cookies from 'js-cookie'

$(window).on('load', function () {
    const cookiePopup = $('.cookie-popup');

    if (!Cookies.get('display-cookie-popup')) {
        Cookies.set('display-cookie-popup', 'true');
    }

    if (Cookies.get('display-cookie-popup') === 'true') {
        cookiePopup.removeClass('--hidden');
    }

    cookiePopup.on('click', '.js-close-cookie-popup', function (e) {
        e.preventDefault();

        cookiePopup.remove();
        Cookies.set('display-cookie-popup', 'false', {expires: 365});
    });
});

const faqs = document.querySelectorAll('[data-behaviour="toggle-faq"]');

faqs.forEach((faq) => {
    const parent = faq.closest('.faq');

    faq.addEventListener('click', (e) => {
        e.preventDefault();

        parent.classList.toggle('faq--active');
    });
});

window.addEventListener('triggerTabChange', () => {
    faqs.forEach((faq) => {
        faq.closest('.faq').classList.remove('faq--active');
    });
});

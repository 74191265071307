import Cookies from 'js-cookie'
$(window).on('load', function () {
    const cookiePopup = $('.site-banner');

    cookiePopup.on('click', '[data-behaviour="close-site-banner"]', function (e) {
        e.preventDefault();

        cookiePopup.remove();
        Cookies.set('hide-site-banner', 'true', {expires: 365});
    });
});

import Utilities from "./utilities";

const utilities = new Utilities();

export default class incrementViews {
    constructor() {
        if (document.getElementsByClassName('.single-post').length < 1) return;

        let data = {
            action: 'incrementViews',
            post_id: theme_object.post_id,
            security: theme_object.security,
            type: 'AJAX',
            user_agent: navigator.userAgent
        };

        let queryString = utilities.param(data);
        fetch('https://camps-international.localhost/wp-admin/admin-ajax.php?' + queryString)
            .then(response => response.json())
            .then(function (result) {
                console.log(result)
            })
            .catch(function (error) {
                console.log('Error during fetch: ' + error.message);
            });
    }
}


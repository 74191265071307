const formFields = document.querySelectorAll('.gform_body .gfield');

if (formFields.length > 0) {
    formFields.forEach((element) => {
        // Exit early if field is hidden
        if (element.classList.contains('gform_hidden') || element.classList.contains('captcha-wrapper')) return;

        const label = element.querySelector('label');
        if (null !== label) {
            const child = document.createElement('div');
            child.classList.add('floating-label');
            child.innerHTML = label.innerHTML;

            element.querySelector('.ginput_container').appendChild(child);

            element.querySelectorAll('input').forEach((inputElement) => {
                inputElement.addEventListener('focus', (e) => {
                    e.currentTarget.closest('.gfield').classList.add('--active');
                });

                inputElement.addEventListener('focusout', (e) => {
                    if (e.target.value !== undefined) {
                        e.currentTarget.closest('.gfield').classList.add('--has-value');
                    }

                    if (e.target.value === '') {
                        e.currentTarget.closest('.gfield').classList.remove('--has-value');
                    }

                    e.currentTarget.closest('.gfield').classList.remove('--active');
                });
            });

            window.addEventListener('load', () => {
                element.querySelectorAll('input').forEach((inputElement) => {
                    if (inputElement.value !== '') {
                        inputElement.closest('.gfield').classList.add('--has-value');
                    }
                });
            });
        }
    });
}

window.addEventListener('load', timeline);
window.addEventListener('resize', timeline);

function timeline() {
    const timelineElement = document.querySelectorAll('[data-behaviour="timeline"]');
    const animateDuration = 500;
    let allowClick = true;

    timelineElement.forEach((element) => {
        const scrollWidth = element.querySelector('.timeline__entries').scrollWidth;
        const scrollWrapper = element.querySelector('.tns-ovh');
        const containerWidth = element.querySelector('.container').offsetWidth;
        const itemWidth = element.querySelector('.entry').offsetWidth;
        const prevButton = element.querySelector('[data-controls="prev"]');
        const nextButton = element.querySelector('[data-controls="next"]');

        prevButton.disabled = true;

        scrollWrapper.addEventListener('scroll', (scrollWrapper) => {
            const scrollLeft = scrollWrapper.currentTarget.scrollLeft;

            scrollLeft < (itemWidth / 2) ? prevButton.disabled = true : prevButton.disabled = false;
            scrollLeft + containerWidth > scrollWidth ? nextButton.disabled = true : nextButton.disabled = false;
        });

        prevButton.addEventListener('click', (e) => {
            e.preventDefault();

            // scrollWrapper.scrollLeft = scrollWrapper.scrollLeft - itemWidth;

            animateTimeline($('.tns-ovh'), scrollWrapper.scrollLeft - itemWidth);
        });

        nextButton.addEventListener('click', (e) => {
            e.preventDefault();

            // scrollWrapper.scrollLeft = scrollWrapper.scrollLeft + itemWidth;

            animateTimeline($('.tns-ovh'), scrollWrapper.scrollLeft + itemWidth);
        });
    });

    function animateTimeline(element, scrollLeft) {
        if (allowClick !== true) return;

        element.animate({
            scrollLeft: scrollLeft
        }, {
            duration: animateDuration,
            start: function () {
                allowClick = false;
            },
            complete: function () {
                allowClick = true;
            }
        });
    }
}
